<template>
    <div class="map" ref="map"></div>
</template>

<script>
export default {
    data() {
        return {
            map: '',
            center: { lat: 22.372632625379143, lng: 114.10759978056221 },
            marker: '',
            markerPosition: { lat: 22.372632625379143, lng: 114.10759978056221 },
        };
    },
    mounted() {
        window.googleInitMap = () => {
            try {
                let zoom = 16;
                if (window.innerWidth < 768) {
                    zoom = 15;
                }
                this.map = new google.maps.Map(this.$refs.map, {
                    center: this.center,
                    zoom: zoom,
                });
                this.marker = new google.maps.Marker({
                    position: this.markerPosition,
                    map: this.map,
                });
            } catch (err) {}
        };
        let googleMap = document.getElementById('google-map');
        if (!googleMap) {
            let scriptObj = document.createElement('script');
            scriptObj.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCQc0iLK2Wm6h4tuuJkECYMlwNYuox8v10&libraries=&v=weekly';
            scriptObj.setAttribute('id', 'google-map');
            document.body.appendChild(scriptObj);
            scriptObj.onload = () => {
                window.googleInitMap();
            };
            scriptObj.onerror = () => {};
        }
        if (googleMap) {
            window.googleInitMap();
        }
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.map {
    width: 100%;
    height: 100%;
    background: url(~@/assets/imgs/map_error.png) center no-repeat;
    background-size: cover;
}
</style>
