<template>
    <div class="information">
        <div class="banner" ref="banner" :style="[bannerUrl]"></div>
        <main class="main">
            <h4>{{ $t('m.10002') }}</h4>
            <div class="container" ref="container">
                <div class="left">
                    <ul ref="leftNav">
                        <li v-for="(item, index) in yearList" :key="item._id" :class="['pointer', { active: activeIndex == index }]" @click="navClick(item, index)">
                            {{ item._id }}
                        </li>
                    </ul>
                </div>
                <div class="right" ref="rightMsg">
                    <ul>
                        <li v-for="(item, index) of articleList" :key="item._id" :ref="item.ref">
                            <h4 class="oneline">{{ item.title }}</h4>
                            <div class="des oneline">{{ item.description }}</div>
                            <div class="bottom">
                                <div class="more pointer" @click="showDetail(index)">
                                    <span>{{ $t('m.10034') }}</span>
                                    <i class="iconfont icon-arrowRight"></i>
                                </div>
                                <span class="time">{{ item.publish_time | timeFormat('y.mm.dd') }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </main>
        <home-footer></home-footer>
        <div v-if="articleList.length">
            <div :class="['modal', { lang_en: lang == 'en_US' }]" v-show="showModal" @touchmove.stop.prevent="() => {}">
                <div class="modal-container">
                    <img @click="showModal = false" class="pointer" src="@/assets/imgs/close.png" alt="" />
                    <div class="modal-top">
                        <div>{{ articleList[dataIndex].publish_time | timeFormat('y.mm.dd') }}</div>
                        <h4>{{ articleList[dataIndex].title }}</h4>
                    </div>
                    <div class="modal-main">
                        <div v-html="articleList[dataIndex].content"></div>
                        <span>{{ articleList[dataIndex].citation }}</span>
                    </div>
                    <div class="modal-bottom">
                        <div :class="['pre', 'pointer', { disable: dataIndex <= 0 }]" @click="goPreProject">
                            <i class="iconfont icon-arrowLeft"></i>
                            <span>上一篇</span>
                        </div>
                        <div :class="['next', 'pointer', { disable: dataIndex >= articleList.length - 1 }]" @click="goNextProject">
                            <span>下一篇</span>
                            <i class="iconfont icon-arrowRight"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import homeHead from '@/components/homeHead.vue';
import homeFooter from '@/components/homeFooter.vue';
import { scrollAnimation } from '@/utils/tools.js';
import { mapGetters } from 'vuex';
export default {
    components: { homeHead, homeFooter },
    name: 'Information',
    data() {
        return {
            activeIndex: 0, // 左侧导航栏选中索引
            scrollTop: 0, // Y轴滚动距离
            containerTop: null, // 页面滚动左侧导航栏切换固定定位Y轴距离
            rightTopArr: [], // 左侧导航栏对应的右侧容器Y轴需要滚动距离
            showModal: false, // 是否显示modal框
            dataIndex: 0, // modal显示数据索引
            yearList: [],
            articleList: [],
            year: '',
        };
    },
    computed: {
        ...mapGetters({
            appData: 'app/appData',
        }),
        lang() {
            return this.$i18n.locale || 'zh_HK';
        },
        bannerUrl() {
            let url = this.isMobile
                ? this.appData.mobile_article_image && this.appData.mobile_article_image.content
                : this.appData.pc_article_image && this.appData.pc_article_image.content;
            return {
                background: 'url(' + url + ') center no-repeat',
            };
        },
    },
    watch: {
        showModal(newVal) {
            if (newVal) document.body.classList.add('fixed');
            else document.body.classList.remove('fixed');
        },
    },
    created() {
        this.getDatelist();
    },
    mounted() {
        window.scrollTo(0, 0);
        // 监听滚动事件，切换左侧的导航栏选中索引
        window.addEventListener('scroll', this.scrollEvent, false);
        // 监听页面大小改变事件，重新执行初始化函数，主要用于移动端和pc端切换，由于样式不用引发的错误
        window.addEventListener('resize', this.init, false);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
        window.removeEventListener('resize', this.init);
    },
    methods: {
        // 初始化
        init() {
            // 页面滚动左侧导航栏切换固定定位Y轴距离
            this.containerTop = this.$refs.container.offsetTop - this.$refs.banner.offsetTop;
            // 移动端左侧导航栏位于顶部，需要算出高度
            const leftNavHeight = this.$refs.rightMsg.offsetTop - this.$refs.container.offsetTop;
            this.rightTopArr.length = 0;
            let index = -1;
            for (const item of this.articleList) {
                if (item.ref) {
                    index++;
                    this.rightTopArr.push({
                        index: index,
                        top: this.$refs[item.ref][index].offsetTop - this.$refs.banner.offsetTop - leftNavHeight,
                    });
                }
            }
        },
        // 滚动条监听函数
        scrollEvent() {
            this.scrollTop = document.body.scrollTop || window.pageYOffset || document.documentElement.scrollTop;
            const nav = this.$refs.leftNav;
            if (this.scrollTop > this.containerTop) nav.classList.add('fixed');
            else nav.classList.remove('fixed');
            const arr = [...this.rightTopArr];
            arr.reverse();
            for (const item of arr) {
                if (this.scrollTop >= item.top) {
                    this.activeIndex = item.index;
                    break;
                }
            }
        },
        // 左侧导航栏点击
        navClick(item, index) {
            // this.year = item._id;
            // this.articleList = [];
            // this.getArticleList();
            // 自定义的滚动函数
            console.log(this.rightTopArr);
            scrollAnimation(this.scrollTop, this.rightTopArr[index].top);
        },
        // 点击查看详情
        showDetail(index) {
            this.dataIndex = index;
            this.showModal = true;
        },
        getDatelist() {
            this.$api.getDatelist({ platform: this.isMobile ? 'h5' : 'pc' }).then((res) => {
                if (res.code == 200) {
                    this.yearList = res.data.sort((a, b) => {
                        return b._id - a._id;
                    });
                    this.year = this.yearList[0]?._id || '';
                    this.getArticleList();
                }
            });
        },
        getArticleList() {
            //数量太少了，暂全部获取year: this.year,
            this.$api.getArticleList({ platform: this.isMobile ? 'h5' : 'pc' }).then((res) => {
                if (res.code == 200) {
                    // this.articleList = [...this.articleList, ...res.data.list];
                    res.data.list.sort((a, b) => {
                        return b.publish_time - a.publish_time;
                    });
                    let years = [];
                    res.data.list.map((item) => {
                        let year = new Date(item.publish_time).getFullYear();
                        if (!years.includes(year)) {
                            item.ref = 'ref-year';
                            years.push(year);
                        }
                        item.year = year;
                    });
                    this.articleList = res.data.list;
                    this.$nextTick(() => {
                        // 调用初始化函数，获取dom位置信息
                        this.init();
                    });
                }
            });
        },
        // 上一篇
        goPreProject() {
            if (this.dataIndex <= 0) {
                return;
            }
            this.dataIndex--;
        },
        // 下一篇
        goNextProject() {
            if (this.dataIndex >= this.articleList.length - 1) {
                return;
            }
            this.dataIndex++;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .information {
        padding-top: vv(40);
        .banner {
            width: 100%;
            height: vv(138);
            background: url(~@/assets/imgs/banner_inf_m.png) center no-repeat;
            background-size: cover;
        }
        .main {
            margin-top: vv(40);
            & > h4 {
                font-size: vv(15);
                font-weight: 700;
                text-align: center;
                color: $b29;
                margin-bottom: vv(20);
            }
            // .container {
            // display: flex;
            // }
            .left {
                width: 100%;
                height: vv(40);
                ul {
                    width: 100vw;
                    margin-left: vv(-12);
                    padding: 0 vv(12);
                    background: #fafbfc;
                    height: vv(40);
                    display: flex;
                    overflow: hidden;
                    &.fixed {
                        position: fixed;
                        top: vv(40);
                    }
                    li {
                        margin: 0 vv(20);
                        height: vv(40);
                        line-height: vv(40);
                        font-size: vv(14);
                        color: $b3;
                        border-bottom: 1px solid transparent;
                        &.active {
                            color: #009aa4;
                            border-bottom-color: #009aa4;
                        }
                    }
                }
            }
            .right {
                width: 100%;
                overflow: hidden;
                ul {
                    li {
                        padding: vv(20) 0 vv(10);
                        border-bottom: 1px dotted #ccc;
                        h4 {
                            font-size: vv(15);
                            font-weight: 600;
                            color: $b3;
                            margin-bottom: vv(10);
                        }
                        .des {
                            display: none;
                        }
                        .bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .more {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                font-size: vv(12);
                                color: #009aa4;
                                span {
                                    margin-right: vv(7);
                                }
                                i {
                                    font-size: vv(13);
                                }
                            }
                            span.time {
                                font-size: vv(12);
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
        .modal {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($color: #000000, $alpha: 0.5);
            z-index: 2022;
            &.lang_en {
                line-height: 1.2;
                .modal-container .modal-main {
                    line-height: 1.3;
                }
            }
            .modal-container {
                position: absolute;
                width: vv(351);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 4px;
                overflow: hidden;
                img {
                    position: absolute;
                    right: vv(10);
                    top: vv(10);
                    width: vv(25);
                    height: vv(25);
                }
                .modal-top {
                    min-height: vv(129);
                    padding: vv(15) vv(10) vv(15);
                    background: url(~@/assets/imgs/banner_inf_2_m.png) center no-repeat;
                    background-size: 100% 100%;
                    div {
                        margin-bottom: vv(13);
                        font-size: vv(12);
                        color: rgba($color: #fff, $alpha: 0.8);
                    }
                    h4 {
                        font-size: vv(16);
                        color: #fff;
                    }
                }
                .modal-main {
                    padding: vv(15) vv(10) vv(40);
                    background-color: #fff;
                    div {
                        font-size: vv(14);
                        color: #333333;
                        margin-bottom: vv(10);
                    }
                    span {
                        font-size: vv(12);
                        color: #666666;
                    }
                }
                .modal-bottom {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 769px) {
    .information {
        padding-top: 68px;
        .banner {
            width: 100%;
            height: 503px;
        }
        .main {
            margin-top: 80px;
            display: flex;
            justify-content: center;
            & > h4 {
                display: none;
            }
            .container {
                display: flex;
                width: 100%;
                padding: 0 15px;
            }
            .left {
                width: 99px;
                ul {
                    width: 99px;
                    &.fixed {
                        position: fixed;
                        top: 68px;
                    }
                    li {
                        margin-bottom: 60px;
                        font-size: 24px;
                        color: $b3;
                        border-right: 3px solid transparent;
                        &.active {
                            color: #009aa4;
                            border-right-color: #009aa4;
                        }
                    }
                }
            }
            .right {
                flex: 1;
                border-left: 2px solid #eee;
                padding-left: 28px;
                overflow: hidden;
                ul {
                    li {
                        padding: 20px 20px 40px;
                        transition: all 0.5s;
                        margin-bottom: 40px;
                        &:hover {
                            box-shadow: 0px 6px 20px 0px rgba(33, 71, 78, 0.12);
                            transform: translateY(-5px);
                        }
                        h4 {
                            font-size: 28px;
                            color: $b3;
                        }
                        .des {
                            font-size: 26px;
                            color: $b6;
                            margin: 20px 0 40px;
                        }
                        .bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .more {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                font-size: 22px;
                                color: #009aa4;
                                span {
                                    margin-right: 14px;
                                }
                                i {
                                    font-size: 26px;
                                }
                            }
                            span.time {
                                font-size: 24px;
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
        .modal {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($color: #000000, $alpha: 0.5);
            z-index: 2022;
            &.lang_en {
                line-height: 1.2;
                .modal-container .modal-top,
                .modal-container .modal-main {
                    div {
                        font-size: 24px;
                        line-height: 1.3;
                    }
                    span {
                        font-size: 20px;
                    }
                    h4 {
                        font-size: 28px;
                    }
                }
            }
            .modal-container {
                position: absolute;
                width: 768px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                img {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    width: 50px;
                    height: 50px;
                }
                .modal-top {
                    height: 241px;
                    padding: 29px 100px 0;
                    background: url(~@/assets/imgs/banner_inf_2.png) center no-repeat;
                    background-size: 100% 100%;
                    div {
                        margin-bottom: 18px;
                        font-size: 24px;
                        color: rgba($color: #fff, $alpha: 0.5);
                    }
                    h4 {
                        font-size: 32px;
                        color: #fff;
                    }
                }
                .modal-main {
                    padding: 30px 100px;
                    background-color: #fff;
                    height: 400px;
                    overflow-y: auto;
                    div {
                        font-size: 28px;
                        color: #333333;
                        margin-bottom: 30px;
                    }
                    span {
                        font-size: 24px;
                        color: #666666;
                    }
                }
                .modal-bottom {
                    margin-top: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .pre {
                        margin-right: 40px;
                        &::after {
                            right: 0;
                        }
                    }
                    .next {
                        &::after {
                            left: 0;
                        }
                    }
                    .pre,
                    .next {
                        position: relative;
                        width: 132px;
                        height: 42px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 20px;
                        font-size: 18px;
                        color: #009aa4;
                        background-color: transparent;
                        transition: all 0.3s;
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            height: 100%;
                            width: 0;
                            background-color: #009aa4;
                            transition: width 0.3s;
                            z-index: -1;
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background-color: #fff;
                            z-index: -1;
                        }
                        &:hover {
                            color: #fff;
                            &::after {
                                width: 100%;
                            }
                        }
                        i {
                            font-size: 24px;
                        }
                        &.disable {
                            color: #ccc;
                            &:hover {
                                color: #ccc;
                                &::after {
                                    width: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .information {
        .main {
            .container {
                width: 992px;
            }
        }
        .modal {
            .modal-container {
                width: 990px;
                .modal-top {
                    height: 211px;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
    .information {
        .main {
            .container {
                width: 1200px;
            }
        }
        .modal {
            .modal-container {
                width: 1200px;
            }
        }
    }
}
</style>
