<template>
    <footer class="footer">
        <div class="container">
            <h4>{{ $t('m.10066') }}</h4>
            <div class="content">
                <aside class="left">
                    <google-map />
                </aside>
                <address class="right">
                    <dl>
                        <dd>
                            <img src="@/assets/imgs/phone.png" alt="" />
                            <span>{{ appData.mobile && appData.mobile.title }}</span>
                        </dd>
                        <dt>{{ appData.mobile && appData.mobile.content }}</dt>
                        <dd>
                            <img src="@/assets/imgs/email.png" alt="" />
                            <span>{{ appData.email && appData.email.title }}</span>
                        </dd>
                        <dt>{{ appData.email && appData.email.content }}</dt>
                        <dd>
                            <img src="@/assets/imgs/person.png" alt="" />
                            <span>{{ appData.address && appData.address.title }}</span>
                        </dd>
                        <dt>{{ appData.address && appData.address.content }}</dt>
                    </dl>
                </address>
            </div>
            <p>{{ appData.copyright_starluxe && appData.copyright_starluxe.content }}</p>
        </div>
    </footer>
</template>

<script>
import googleMap from '@/components/googleMap.vue';
import { mapGetters } from 'vuex';
export default {
    components: { googleMap },
    computed: {
        ...mapGetters({
            appData: 'app/appData',
        }),
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .footer {
        margin-top: vv(30);
        padding-bottom: vv(10);
        background: #fafbfc;
        h4 {
            font-size: vv(15);
            text-align: center;
            height: vv(58);
            line-height: vv(58);
        }
        .content {
            .left {
                width: 100%;
                height: vv(164);
                background-color: #fff;
            }
            .right {
                width: 100%;
                padding: vv(20);
                dl {
                    dd {
                        display: flex;
                        align-items: center;
                        margin-bottom: vv(10);
                        img {
                            width: vv(18);
                            height: vv(18);
                            margin-right: vv(6);
                        }
                        span {
                            font-size: vv(15);
                            color: $b3;
                            font-weight: 600;
                        }
                    }
                    dt {
                        padding-left: vv(24);
                        font-size: vv(13);
                        color: $b9;
                        margin-bottom: vv(20);
                        &:nth-of-type(3) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        p {
            margin-top: vv(20);
            text-align: center;
            font-size: vv(12);
            color: $b9;
        }
    }
}

@media (min-width: 769px) {
    .footer {
        margin-top: 60px;
        padding-bottom: 10px;
        background: #fafbfc;
        display: flex;
        justify-content: center;
        .container {
            padding: 0 15px;
        }
        h4 {
            text-align: center;
            height: 116px;
            line-height: 116px;
        }
        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                width: 490px;
                height: 311px;
                background-color: #fff;
            }
            .right {
                flex: 1;
                padding-left: 10%;
                dl {
                    dd {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 12px;
                        }
                        span {
                            font-size: 18px;
                            color: $b3;
                        }
                    }
                    dt {
                        padding-left: 32px;
                        font-size: 16px;
                        color: $b9;
                        margin-bottom: 40px;
                        &:nth-of-type(3) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        p {
            margin-top: 80px;
            text-align: center;
            font-size: 14px;
            color: $b9;
        }
    }
}
@media (min-width: 1200px) {
    .footer {
        .container {
            width: 1200px;
        }
    }
}
</style>
